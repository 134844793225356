import axios from "@/services/axios"
import UrlKeeper from '@/services/UrlKeeper.js'
import calendarService from './calendar.service'
import socketService from '../socket/socket.service'
import EventEmitter from "events";

class eventGroupService extends EventEmitter {
  constructor() {
    super()
    socketService.on('calendar:deleteEventGroup', async (data) => {
      if (!!data.startDate) {
        let date = new Date(data.startDate)
        await calendarService.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:setOperatorOnEvent', async (data) => {
      if (!!data.startDate) {
        let date = new Date(data.startDate)
        await calendarService.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:deleteEventService', async (data) => {
      if (!!data.startDate) {
        let date = new Date(data.startDate)
        await calendarService.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })

    socketService.on('calendar:updateEventService', async (data) => {
      if (!!data.startDate) {
        let date = new Date(data.startDate)
        await calendarService.reloadDayCache({ date })
        this.emit('events-cached-changes')
      }
    })
  }

  list(start, end, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (start === undefined && end === undefined) {
        if (filters) {
          start = new Date('2000-01-01')
          end = new Date('2099-01-01')
        }
        else {
          reject(new Error('Too much events'))
          return
        }
      }
      axios.post(apiUrl + '/events/groups/list', {
        start: start,
        end: end,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(new Error('error during the call'))
        }
      })
    });
  }

  print(start, end, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + '/events/groups/print', {
        start: start,
        end: end
      }, `Appuntamenti.pdf`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  addServiceToExistingGroup(group, service, operators, phase, startDate) {
    return new Promise((resolve, reject) => {
      if (!startDate) {
        reject(new Error('please specify start date'))
      } else if (!group || !group.id || !service || !service.id || !operators)
        reject('group, service or operators not specified')
      else {
        let serviceOrPhase = [service]
        if (!!phase) {
          serviceOrPhase = [phase]
        }

        const startAndEnd = this.calculateStartAndEndDate(serviceOrPhase, startDate, 0)
        calendarService.createEventAndGroup({
          startDate: startAndEnd.startDate,
          endDate: startAndEnd.endDate,
        }, group, true).then((newEventForService) => {
          this.setEventServices(newEventForService, { service: service, phase: phase, operators: operators }).then((newEventService) => {
            newEventService = !!newEventService[0] ? newEventService[0].results : undefined
            resolve({
              event: newEventForService,
              eventService: newEventService
            })
          })
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  async createServiceToExistingGroup(event, service, operators, phase, startDate) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      if (!startDate) startDate = event.endDate
      if (!event || !event.id || !service || !service.id || !operators)
        reject('event, service or operators not specified')
      else {
        let serviceOrPhase = [service]
        if (!!phase) {
          serviceOrPhase = [phase]
        }

        const startAndEnd = this.calculateStartAndEndDate(serviceOrPhase, startDate, 0)
        let createAndAggregateParams = {
          event: {
            startDate: startAndEnd.startDate,
            endDate: startAndEnd.endDate,
          },
          masterEvent: event
        }

        let setServiceParams = {
          service: {
            id: service.id
          }
        }
        if (!!phase && !!phase.id) {
          setServiceParams.servicePhase = {
            id: phase.id
          }
        }

        let setOperatorParams = {
          operators: operators
        }

        axios.post(apiUrl + '/events/createServiceToExistingGroup', { createAndAggregateParams, setServiceParams, setOperatorParams }).then((response) => {
          if (response.success)
            resolve(response.results)
          else
            reject(response.results)
        })
      }

    })
  }

  addServiceToExistingGroupAggregate(event, service, operators, phase, startDate) {
    return new Promise((resolve, reject) => {
      if (!startDate) startDate = event.endDate
      if (!event || !event.id || !service || !service.id || !operators)
        reject('event, service or operators not specified')
      else {
        let serviceOrPhase = [service]
        if (!!phase) {
          serviceOrPhase = [phase]
        }

        const startAndEnd = this.calculateStartAndEndDate(serviceOrPhase, startDate, 0)
        calendarService.createEventAndAggregate({
          startDate: startAndEnd.startDate,
          endDate: startAndEnd.endDate,
        }, event, true).then((newEventForService) => {
          this.setEventServices(newEventForService, { service: service, phase: phase, operators: operators }).then((newEventService) => {
            newEventService = !!newEventService[0] ? newEventService[0].results : undefined
            resolve({
              event: newEventForService,
              eventService: newEventService
            })
          })
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  setCustomer(event, customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customer.id) {
        reject('customer id not specified')
      } else {
        axios.post(apiUrl + '/events/' + event.id + '/setCustomerOnGroup', customer).then((response) => {
          if (response.success) resolve(response.results)
          else reject(response.results)
        })
      }
    })
  }

  setCustomerWithGroup(group, customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customer || !customer.id) {
        reject('customer id not specified')
      } else {
        axios.post(apiUrl + '/events/groups/' + group.id + '/setCustomer', customer).then((response) => {
          if (response.success) resolve(response.results)
          else reject(response.results)
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  async setOperator(event, operators) {
    const apiUrl = UrlKeeper.getUrl()

    if (!operators || !Array.isArray(operators) || operators.length == 0) throw new Error('operators is not defined')
    else if (!event || !event.id) throw new Error('event id is not defined')
    //else if (!event.startDate) throw new Error('event start date must be defined')

    const response = await axios.post(apiUrl + '/events/' + event.id + '/services/setOperator', operators)
    if (response.success) {
      if (!calendarService.socketConnected) {
        await calendarService.reloadDayCache({
          date: event.startDate
        })
      }
      return response.results
    }
    else throw response.results
  }

  updateEventService(eventService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventService || !eventService.id) reject(new Error('event service is not defined'))
      else {
        axios.post(apiUrl + '/events/' + eventService.eventId + '/services/update', eventService).then((response) => {
          if (response.success) resolve(response.results)
          else reject(response.results)
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  setEventServices(event, eventService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!event.startDate) throw new Error('specify event start date')

      let params = {
        service: {
          id: eventService.service.id
        }
      }

      if (!!eventService.phase && !!eventService.phase.id) {
        params.servicePhase = {
          id: eventService.phase.id
        }
      }

      params.operatorsLocked = eventService.operatorsLocked

      axios.post(apiUrl + '/events/' + event.id + '/services/setService', params)
        .then((setServiceResponse) => {
          this.setOperator(event, eventService.operators).then((setOperatorResponse) => {
            calendarService.reloadDayCache({
              date: event.startDate
            }).then(() => {
              resolve([setServiceResponse, setOperatorResponse])
            })
          }).catch((err) => {
            reject(err)
          })
        })
    })
  }


  calculateStartAndEndDate(services, startDate, index) {
    if (!Array.isArray(services)) throw (new Error('event services should be an array'))
    else if (services.some((el) => el.minutes == undefined || el.minutes == null)) throw (new Error('event services should be an array'))
    else if (index >= services.length) return {}

    let servicesDurationsBeforeIndex = 0
    if (index != 0) {
      servicesDurationsBeforeIndex = services
        .filter((es, i) => { return i < index })
        .map((es) => { return parseInt(es.minutes) })
        .reduce((a, c) => { return a + c })
    }

    let servicesDurationsUntilIndex = services
      .filter((es, i) => { return i <= index })
      .map((es) => { return parseInt(es.minutes) })
      .reduce((a, c) => { return a + c })

    let result = {}
    result.startDate = new Date((new Date(startDate)).getTime() + (servicesDurationsBeforeIndex * 60000))
    result.endDate = new Date((new Date(startDate)).getTime() + (servicesDurationsUntilIndex * 60000))
    return result
  }

  getFromEvent(eventId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventId) reject('event id not specified')
      else {
        axios.get(apiUrl + '/events/groups/getFromEvent', { eventId: eventId }).then((response) => {
          if (response.success) {
            resolve(response.results[0])
          }
        })
      }
    })
  }

  moveServiceUp(eventService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventService.eventId) {
        reject('eventId is not defined')
        return
      }

      axios.get(apiUrl + '/events/' + eventService.eventId + '/services/moveUp').then((response) => {
        if (response.success) {
          resolve()
        } else {
          reject(response.results)
        }
      })
    })
  }

  moveServiceDown(eventService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventService.eventId) {
        reject('eventId is not defined')
        return
      }

      axios.get(apiUrl + '/events/' + eventService.eventId + '/services/moveDown').then((response) => {
        if (response.success) {
          resolve()
        } else {
          reject(response.results)
        }
      })
    })
  }

  moveServiceInPosition(eventService, position) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventService.eventId) {
        reject('eventId is not defined')
        return
      } else if (position == undefined || position == null) {
        reject('position is not defined')
        return
      }


      axios.get(apiUrl + '/events/' + eventService.eventId + '/services/moveInPosition', { position: position }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      })

    })
  }

  changeServicesDate(eventGroup, date) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventGroup || !eventGroup.id) {
        reject(new Error('event group is not specified'))
      }
      axios.post(apiUrl + '/events/groups/' + eventGroup.id + '/moveDate', { date }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /*
    eventGroup: {
      id: String,
    },
    options {
      ruleType: 'once' | 'daily' | 'weekly' | 'monthly',
      startDate: Date,
      endDate: Date,
      daysOfWeek: Array with element from 0 to 6,
      daysOfMonth: Array with element from 1 to 31,
    }
  */
  duplicateEvents(eventGroup, options) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventGroup || !eventGroup.id) {
        reject(new Error('event group is not specified'))
      }

      axios.post(apiUrl + '/events/groups/' + eventGroup.id + '/duplicate', {
        ruleType: options.ruleType,
        startDate: options.startDate,
        endDate: options.endDate,
        daysOfWeek: options.daysOfWeek,
        daysOfMonth: options.daysOfMonth,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  addToSaloon(eventGroup, taxForSaloon) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventGroup || !eventGroup.id) {
        reject(new Error('event group is not specified'))
      }

      axios.post(apiUrl + '/events/groups/' + eventGroup.id + '/addToSaloon', {
        taxForSaloon: taxForSaloon
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateGroup(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!params || !params.id) reject('group id not specified')
      else {
        axios.post(apiUrl + '/events/groups/update', params).then((response) => {
          if (response.success) {
            resolve(response.results)
          }
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  delete(eventService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventService || !eventService.id || !eventService.eventId) {
        reject('event service id or eventId not specified')
        return
      }

      axios.post(apiUrl + '/events/' + eventService.eventId + '/services/delete', eventService)
        .then((response) => {
          calendarService.deleteEvent({ id: eventService.eventId }).then(() => {
            resolve(response)
          }).catch((err) => {
            reject(err)
          })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }


  async deleteGroup(eventGroup, fromBooking = false) {
    const apiUrl = UrlKeeper.getUrl()

    if (!eventGroup || !eventGroup.id) {
      throw new Error('event service id or eventId not specified')
    }

    const response = await axios.post(apiUrl + '/events/groups/' + eventGroup.id + '/delete', { fromBooking })

    if (response.success) {
      await calendarService.reloadDayCache({
        date: new Date(response.results.startDate)
      })
      return response.results
    } else {
      throw response.results
    }
  }

  deleteAllServices(eventGroup) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!eventGroup || !eventGroup.id) {
        reject('event group or event group id not specified')
        return
      }

      axios.post(apiUrl + '/events/groups/' + eventGroup.id + '/deleteAllServices').then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new eventGroupService();